/* Navbar.css */
nav {
    background-color: #333;
    color: #fff;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.highlighted {
    background-color: white;
    padding: 5px;
    border-radius: 5px;
}

.left-nav {
    margin-right: auto;
}

.right-nav {
    margin-left: auto;
}

li {
    margin-right: 10px;
}

/* Style the active link and non-active links */
.active, NavLink:not(.active) {
    font-weight: bold;
    text-decoration: underline;
    color: yellow !important;
}

NavLink:hover {
    text-decoration: none;
}
