/* ApiKeyManager.css */

/* Add styling for the overall container specific to ApiKeyManager */
.AlertConfig {
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

    /* Add general styling to the table */
    .AlertConfig table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
    }

    .AlertConfig th, .AlertConfig td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
        padding: 5px;
        border: 1px solid #ddd;
        background-color: #f2f2f2;
        white-space: normal; /* Allow text to wrap within cells */
        vertical-align: top; /* Align text at the top of cells */
        word-wrap: break-word; /* Allow long words to wrap onto the next line */
    }

    .AlertConfig th {
        background-color: #f2f2f2;
    }

    /* Add styling for action buttons */
    .AlertConfig button {
        background-color: #4caf50;
        color: white;
        padding: 6px 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

        .AlertConfig button:hover {
            background-color: #45a049;
        }
