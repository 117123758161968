.token-manager-container {
    text-align: center;
    margin: 50px auto;
    max-width: 600px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.token-manager-title {
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
}

.form-group {
    text-align: left;
    margin-bottom: 15px;
}

    .form-group label {
        display: block;
        margin-bottom: 5px;
        color: #555;
        font-size: 18px;
    }

    .form-group input[type="text"] {
        width: 100%;
        padding: 12px;
        border: 2px solid #ccc;
        border-radius: 5px;
        box-sizing: border-box;
        font-size: 18px;
    }

.token-container {
    margin-top: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    /* Ensure the container can display long text with a scrollbar */
    overflow: auto;
}

.token-string {
    font-size: 18px;
    margin-bottom: 10px;
    white-space: pre-wrap;
    max-height: 200px;
    padding-right: 20px;
}

.button {
    padding: 12px 24px;
    cursor: pointer;
    border-radius: 10px;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.2s;
}

.generate-button {
    background-color: #007bff;
    color: #fff;
    border: none;
}

    .generate-button.clicked {
        background-color: #004494;
        transform: scale(0.95);
    }

.copy-button {
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: #4caf50;
    color: #fff;
    border: none;
}

/* Hover effect for buttons */
.button:hover {
    background-color: #0056b3;
}

.copy-button:hover {
    background-color: #368f36;
}

.copy-button.clicked {
    background-color: #2e7a2e;
    transform: scale(0.95);
}

.generate-button.clicked {
    background-color: #004494; /* Even darker blue when clicked */
    transform: scale(0.95); /* Slightly shrink the button */
}