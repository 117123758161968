/* Style the message logs container */
.message-logs-container {
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
    margin: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 100%
}

/* Style the table title */
.message-logs-title {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 10px;
}

/* Style the table */
.message-logs-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    table-layout: auto;
}

    /* Style the table header (<thead>) */
    .message-logs-table thead {
        background-color: #f2f2f2;
        font-weight: bold;
        text-align: left;
        height: 40px; /* Set the height for the header row */
    }

    /* Style the table body (<tbody>) */
    .message-logs-table tbody {
        background-color: #f5f5f5;
    }

    /* Style table rows (<tr>) */
    .message-logs-table tr {
        /* Add any common styles for table rows here */
        height: 40px; /* Set the height for table rows */
    }

    /* Style table cells in the header row (<th>) */
    .message-logs-table th {
        padding: 5px;
        border: 1px solid #ddd;
        white-space: normal; /* Allow text to wrap within cells */
        vertical-align: top; /* Align text at the top of cells */
        word-wrap: break-word; /* Allow long words to wrap onto the next line */
    }

    /* Style table cells in the body row (<td>) */
    .message-logs-table td {
        padding: 5px;
        border: 1px solid #ddd;
        white-space: normal; /* Allow text to wrap within cells */
        vertical-align: top; /* Align text at the top of cells */
        word-wrap: break-word; /* Allow long words to wrap onto the next line */
        width: 200px; /* Set a specific width for cells */
    }

/* Style filter container */
.filter-container {
    display: flex; /* Display children in a row */
    align-items: center; /* Vertically align children */
    margin-bottom: 10px; /* Add space between filters and table */
    background-color: lightgreen; /* Add light green background color to the entire line */
    padding: 10px; /* Add padding for spacing */
    border-radius: 5px; /* Add rounded corners */
}

/* Style filter items (labels and inputs) */
.filter-item {
    display: flex; /* Display label and input in a row */
    align-items: center; /* Vertically align label and input */
    margin-right: 20px; /* Add space between filter items */
}

    /* Style filter labels */
    .filter-item label {
        margin-right: 5px; /* Add space between label and input */
    }

    /* Style filter inputs */
    .filter-item input[type="text"] {
        border-radius: 4px;
        font-size: 16px;
        transition: border-color 0.3s;
        width: 300px; /* Set width for input field */
    }

    .filter-item .react-datepicker-wrapper .react-datepicker__input-container input {
        width: 90px; /* Set width for DatePicker input */
    }

    .filter-item input[type="text"]:focus {
        border-color: #007bff; /* Change border color on focus for input field */
    }

    .filter-item .react-datepicker-wrapper .react-datepicker__input-container input:focus {
        border-color: #007bff; /* Change border color on focus for DatePicker input */
    }

    .filter-icon {
        margin-left: 15px;
        cursor: pointer;
    }

.number-input {
    width: 60px;
    font-size: 16px;
}

/* Style for green text */
.green-text {
    color: green;
}

/* Style for red text */
.red-text {
    color: red;
}

.header-with-dropdown {
    position: relative;
    height: 60px;
}

.dropdown {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    width: 100px;
}

    .dropdown option {
        padding: 5px;
        background-color: #fff;
        color: #333;
    }

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px; /* Adjust the margin as needed */
}

.spinner {
    margin-bottom: 10px; /* Adjust the margin as needed */
}

.loading-text {
    font-size: 18px; /* Adjust the font size as needed */
    color: #666; /* Adjust the color as needed */
}

.dropdown-spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dropdown-spinner-icon {
    position: relative; /* Change to relative positioning */
    top: 12px; /* Adjust the top offset as needed */
    font-size: 16px; /* Adjust the font size of the spinner icon */
}




