/* Styles for labels and input fields in the modal */
.ReactModal__Content label {
    display: block;
    margin-bottom: 10px;
}

.ReactModal__Content input {
    width: calc(100% - 16px); /* Adjust the padding (8px on each side) */
    padding: 8px;
    margin-bottom: 15px;
}

/* Styles for buttons in the modal */
.ReactModal__Content button {
    background-color: blue; /* Blue color for buttons */
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 8px; /* Add space between buttons */
}

    .ReactModal__Content button:hover {
        background-color: lightblue;
    }

    .ReactModal__Content button.cancel {
        background-color: red; /* Red color for Cancel button */
    }

    .ReactModal__Content button.cancel:hover {
        background-color: lightcoral; /* Red color for Cancel button */
    }
/* Position buttons to the right */
.ReactModal__Content .button-container {
    display: flex;
    justify-content: flex-end;
}

/* Adjust modal width */
.ReactModal__Content {
    width: 40%; 
    margin: 0 auto;
}

/* LogoutModal.css */
.logout-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 80%;
    max-height: 80%;
    overflow: auto; /* Enable scrolling for modal content */
}
