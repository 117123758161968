/* ApiKeyManager.css */

/* Add styling for the overall container specific to ApiKeyManager */
.ApiKeyManager {
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

    /* Add general styling to the table */
    .ApiKeyManager table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
    }

    .ApiKeyManager th, .ApiKeyManager td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }

    .ApiKeyManager th {
        background-color: #f2f2f2;
    }

    /* Add styling for action buttons */
    .ApiKeyManager button {
        background-color: blue;
        color: white;
        padding: 6px 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

        .ApiKeyManager button:hover {
            background-color: lightblue;
        }

        .ApiKeyManager button.disable {
            background-color: red; /* Red color for Cancel button */
        }

            .ApiKeyManager button.disable:hover {
                background-color: lightcoral; /* Red color for Cancel button */
            }

        .ApiKeyManager button.enable {
            background-color: green; /* Red color for Cancel button */
        }

            .ApiKeyManager button.enable:hover {
                background-color: lightgreen; /* Red color for Cancel button */
            }

.td-with-buttons {
    text-align: center !important;
}

    .td-with-buttons button {
        margin-right: 5px;
        margin-bottom: 5px;
    }
